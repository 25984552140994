.ReactModal__Content{
    width: 50%;
    margin: auto;
    height: fit-content;
    border-radius: 30px;
}

.modal-today{
    position: relative;
    z-index: 30 !important;
}

.modal-today .close-modal{
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
}

.img-div{
    width: 50%;
}

.content{
    width: 50%;
}

.today-deal{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10%    ;
}

.css-doq0dk{
    z-index: 0  !important;
}


.popupBox__img {
    position: relative;
    width: 250px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popupBox__img img{
    width: 100%;
  }
  
  .popupBox__img::before {
    content: "";
    position: absolute;
    width: 250px;
    height: 250px;
    background: #fcffe0;
    border-radius: 50%;
  }
  
  .popupBox__img img {
    position: relative;
    max-width: 250px;
    z-index: 1;
  }
  
  .popupBox__contentTwo {
    position: relative;
    width: 300px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popupBox__title {
    color: #333;
    line-height: 1em;
    font-weight: 300;
    font-size: 2em;
  }
  
  .popupBox__titleTwo {
    font-size: 4em;
    color: #FFBA00;
    line-height: 1em;
  }
  
  .popupBox__titleTwo span {
    color: #333;
    font-size: 0.75em;
    text-transform: uppercase;
  }
  
  .popupBox__description {
    font-weight: 300;
  }
  
  .popupBox__btn {
    display: inline-block;
    padding: 10px 20px;
    background: #FFBA00;
    text-decoration: none;
    color: #000;
    margin-top: 15px;
    border-radius: 10px;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #f3f3f3 url(https://www.pikpng.com/pngl/m/302-3024308_close-icon-png-x-png-icon-clipart.png);
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    z-index: 10;
    cursor: pointer;
    border-radius: 50%;
  }
  @media (max-width: 768px) {
    .popupBox__content {
      width: 300px;
      height: auto;
      flex-direction: column;
    }
    .popupBox__img {
      height: 200px;
      transform: translateY(-50px);
    }
    .popupBox__contentTwo {
      height: auto;
      text-align: center;
      padding: 20px;
      padding-top: 0;
    }
    .popupBox__img::before {
      background: #fff;
    }
    .close {
      top: -50px;
      right: -10px;
      /* background: #fff url(./img/close.png); */
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center;
    }
  }
  .credit a{
    text-decoration: none;
    color: #FFBA00;
  }
  .credit {
      margin-top: 10px;
  }
  