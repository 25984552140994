@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

.carousel{
    margin-top: 2% !important;
    /* height: 200px !important; */
    padding-bottom: 100px;
}   

.carousel-img{
    height: 100%;
}

.carousel-img img{
    width: 100%;
    transition: 0.5s;
    object-fit: contain;
}

.carousel-img h2{
    color: rgb(255, 255, 255, 0.823);
    font-family: 'chuck-noon-regular', sans-serif;
    font-weight: 1000;
    font-size: 1em;
    /* text-shadow: 3px 0 rgba(255, 255, 255, 0.823); */
    /* letter-spacing: 0.2ch; */
}

.carousel-img img:hover{
    scale: 1.1;
}

@media only screen and (max-width: 600px) {
    .carousel{
        margin-top: 10% !important;
        height: 60px !important;
    }

    .carousel-img{
        height: 60px;
    }
  }