.footer{
    background-color: #E5AC15;
    padding-top: 30px;
    margin-top: 100px;
}

.footer-top{
    display: flex;

}

.zippy-footer-logo{
    width: 200px;
    /* padding-top: 2%; */
    margin-left: 6%;
    display: flex;
    justify-content: center;
    /* padding: 0% 25% !important ; */
}

.zippy-footer-logo img{
    width: 100%;
    margin: auto;
    object-fit: contain;
}

.social-media{
    width: fit-content;
    padding-left: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-sm{
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    border-radius: 5px;
}

.logo-sm img{
    width: 80%;
    object-fit: contain;
}

.social-media-links{
    display: flex;
    justify-content: space-around;
    object-fit: contain;
    width: 200px;
}

.hr-line{
    border: 2px solid black !important;
    color: black;
    width: 99%;
    margin: auto;
}

.footer-bottom h6{
    text-align: left;
    padding: 20px !important;
    font-weight: bold;
}

.about-contact{
    display: flex;
    justify-content: space-around;
    width: 80% !important;
}


.about-contact{
    color: black;
    text-decoration: none;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
}

.about-contact h5{
    color: white;
    font-weight: bolder;
    font-size: 25px;
}

@media only screen and (max-width: 600px) {
    .about-contact{
        display: none !important;
    }

    .footer-top{
        display: flex;
        justify-content: center;   
    }
  }