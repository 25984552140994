.form-check-input{
    width: auto !important;
    height: auto !important;
}

.form-check{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
}

.category{
    display: flex;
    flex-direction: row !important;
    color: white;
    margin-bottom: 20px;
}

.label-category{
    font-weight: bolder;
    font-size: larger;
}
