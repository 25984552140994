* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .container {
    /* width: 30%; */
    /* background-color: white; */
    padding: 1rem 3rem;
    padding-bottom: 2rem;
    border-radius: 0.5rem;
    border-top: #E5AC15 0.5rem solid;
    background-color: rgba(240, 248, 255, 0.208);
  }

  .login-container
  {
    /* background-image: url('../../images/back.jpg'); */
    background-size: contain;
  }
  
  .body{
    display: flex;
    justify-content: center;
    align-items: center;
  }


  h2 {
    margin: 1rem 0;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }
  
  form div {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  
  input {
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
    font-size: 1.1rem;
  }
  
  button {
    background-color: #E5AC15;
    color: white;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 0.3rem;
  }
  
  span a {
    text-decoration: none;
    color: #E5AC15;
  }
  
  .private {
    height: 100vh;
    width: 100vw;
    background-color: rgb(80, 98, 255);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 3rem;
    flex-direction: column;
    gap: 1rem;
  }
  
  .private button {
    background-color: black;
    padding: 2rem 10rem;
    font-size: 4rem;
    border-radius: 2rem;
  }
  

  .login-container form .form{
    display: flex;
    flex-wrap: wrap;
    max-height: 65vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

}

/* .login-container form input{
    width: 300px;
} */

.login-container form .form input,.login-container form .form button{
    width: 32%;
    /* padding-bottom: 18%; 32:18, i.e. 16:9 */
    margin: auto;

    height: 100%;
    margin-bottom: 2%; /* (100-32*3)/2 */
}


@media only screen and (max-width: 600px) {
  .login-container form .form{
    display: flex;
    flex-wrap: nowrap !important;
    /* max-height: 80vh; */
    /* display: flex; */
    justify-content: space-between;
    /* align-items: center; */
    /* flex-grow: 1; */

}

/* .login-container form input{
    width: 300px;
} */

.login-container form .form input,.login-container form .form button{
    width: 80%;
    /* padding-bottom: 18%; 32:18, i.e. 16:9 */
    margin: auto;

    margin-bottom: 2%; /* (100-32*3)/2 */
}


}

.submit-btn{
  width: 20% !important;
  margin: auto;
}

.auth-form{
  width: 40%;
  color: white;
}

.login-front{
  /* height: 80vh; */
  margin-top: 0 !important;
}