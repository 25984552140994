@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ysabeau&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.product-page{
    height: 80vh;
    width: 60vw;
    margin: auto;
    /* border-radius: 50px; */
    background-color: rgba(255, 255, 255, 0.836);
    margin-top: 30px;

    display: flex;
    position: relative;

}

.full-page{
    min-width: 100vh;
}

.product-img{
    width: 80%;
    height: 100%;
}

.product-img img{
    width: 100%;
    /* border-radius: 50px 0 0 50px; */
    border-top-right-radius: 35%;
    border-bottom-right-radius: 35%;

    height: 100%;
    object-fit: cover;
}

.product-details{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content:center;
}

.product-details div{
    font-weight: bolder;
    font-size: larger;
}

.product-details .product-name{
    font-size: xx-large;
}

.veg-nv-icon {
    width: 40px;
    position: absolute;
    right: 30px;
    top: 30px;
}

.add-to-cart
{
    color: #FFBA00;
    font-size: 30px;
}

.product-name{
    letter-spacing: 3px;
}

.recipe-ingredients{
    display: flex;
    margin-top: 10%;
    justify-content: space-around;
}


.fa-cart-plus{
    font-size: xx-large;
    /* color: #FFBA00; */
}

.round-img{
    position: absolute;
    width: 30%;
    right: -50px;
    top: 0px;
}

ul{
    list-style-type: none;
}

.modal-title{
    color: #FFBA00;
    font-size: 40px;
    font-weight: 100 !important;
    font-family: 'Pacifico', cursive;
}

.steps{
    font-family: 'Ysabeau', sans-serif;
    font-weight: bolder;
    font-size: 25px;

}

.recipe-ing{
    font-family: 'Merriweather', serif;
}