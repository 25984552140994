.front{
    margin-top: 30px !important;
    background-image: url('../../images/back.jpg');
    /* min-height: 93vh; */
    min-height: fit-content;
    width: 95vw;
    background-position: center;
    /* background-repeat: no-repeat; */
    background-size: contain;
    border-radius: 20px;
    border: #7D7D7D solid 8px;
    margin: auto;
    padding-top: 10px;
}

body{
    background-color: #1C1E1B;
}

.zippy{
    width: 40%;
    margin: auto;
    margin-top: 30px;
}

.zippy img{
    width: 100%;
    object-fit: contain;
}

.zippy-logo{
    width: 25%;
    margin: auto;
    margin-top: -9%;
}

.zippy-logo img{
    width: 100%;
    object-fit: contain;
}

/* @media only screen and (max-width: 600px) {
    .front {
      min-height : fit-content;
    }
  } */